<template>
  <div>
    <form @submit="save">
      <div class="form-group">
        <label for="">Concepto</label>
        <input type="text" class="form-control" v-model="concept" max="500" />
      </div>
      <div class="form-group">
        <label for="">Razon de egreso</label>
        <SelectReason ref="elSelRea" v-model="reason_id"></SelectReason>
      </div>
      <div class="form-group">
        <label for="">Monto</label>
        <app-input-money
          :quantity.sync="amount"
          :moneyCode="1"
        ></app-input-money>
      </div>
      <app-button-submit @oneClick="save"></app-button-submit>
    </form>
  </div>
</template>
<script>
import SelectReason from "../reasons/Select.vue";
import { AdminService } from "../AdminService";

export default {
  components: {
    SelectReason
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    concept: "",
    reason_id: 0,
    amount: 0
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    reset() {
      this.concept = "";
      this.reason_id = 0;
      this.amount = 0;
      this.$refs.elSelRea.reset();
    },
    save() {
      AdminService.saveLiquidations(this.$data).then(() => {
        this.$emit("submitted");
      });
    }
  }
};
</script>

B
<style scoped></style>

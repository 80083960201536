<template>
  <div>
    <h4>LIQUIDACIONES</h4>
    <app-table-registers
      ref="tr"
      :getList="getList"
      @btnNew="
        $refs.elModalCreate.show();
        $refs.elCreateForm.reset();
      "
    >
      <template slot="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>FECHA</th>
            <th>CONCEPTO</th>
            <th>MONTO</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, i) in list.data" :key="i">
            <td>{{ l.id }}</td>
            <td>{{ l.created_at | dateFormat }}</td>
            <td>{{ l.concept }}</td>
            <td>
              <app-span-money
                :moneyCode="l.money_code"
                :quantity="l.amount"
              ></app-span-money>
            </td>
            <td>
              <div class="btn-group">
                <router-link
                  v-if="l.path_details"
                  class="btn btn-sm btn-light"
                  :to="l.path_details"
                  title="ver detalles"
                >
                  <i class="fa fa-link"></i>
                </router-link>
                <a
                  href="javascript:void(0)"
                  @click="
                    $refs.elPagar.show();
                    $refs.elLetsPay.load(l);
                  "
                  :class="{ 'btn-light': l.output_id }"
                  class="btn btn-sm btn-success"
                >
                  <i class="fa fa-money-bill"></i>
                </a>
                <a
                  href="javascript:void(0)"
                  class="btn btn-sm btn-light"
                  v-if="l.can_reverse"
                  title="Puedes revertir"
                  @click.prevent="
                    $refs.elModalRevert.show();
                    tempLiquidationId = l.id;
                  "
                >
                  <i class="fas fa-undo-alt"></i>
                </a>
                <a
                  href="javascript:void(0)"
                  class="btn btn-sm btn-danger"
                  v-if="!l.output_id && l.type_id == 10"
                  title="Eliminar"
                  @click.prevent="
                    $refs.elModalDelete.show();
                    tempLiquidationId = l.id;
                  "
                >
                  <i class="fas fa-trash"></i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="elPagar">
      <h4>DETALLES DE DESEMBOLSO</h4>
      <lets-pay ref="elLetsPay" @submitted="$refs.tr.gl()"></lets-pay>
    </app-modal-basic>

    <app-modal-yn
      ref="elModalRevert"
      @yes="revertLiquidation(tempLiquidationId)"
    >
      Revertir la liquidacion <b>{{ tempLiquidationId }}</b
      >, se eliminara el egreso generado.
    </app-modal-yn>

    <app-modal-yn
      ref="elModalDelete"
      @yes="deleteLiquidation(tempLiquidationId)"
    >
      Eliminar la liquidacion <b>{{ tempLiquidationId }}</b
      >.
    </app-modal-yn>

    <app-modal-basic ref="elModalCreate">
      <CreateForm
        ref="elCreateForm"
        @submitted="
          $refs.elModalCreate.hide();
          $refs.tr.gl();
        "
      ></CreateForm>
    </app-modal-basic>
  </div>
</template>
<script>
import { AdminService } from "../AdminService";
import LetsPay from "./Letspay";
import CreateForm from "./CreateForm.vue";

export default {
  components: {
    LetsPay,
    CreateForm
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    list: {},
    tempLiquidationId: 0
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    deleteLiquidation(liquidationId) {
      AdminService.deleteLiquidations(liquidationId).then(() => {
        this.$refs.tr.gl();
      });
    },
    revertLiquidation(liquidationId) {
      AdminService.reverseLiquidation(liquidationId).then(() => {
        this.$refs.tr.gl();
      });
    },
    getList(params) {
      return new Promise((rsv) => {
        AdminService.getLiquidations(params).then((data) => {
          this.list = data;
          rsv(data);
        });
      });
    }
  }
};
</script>

<style></style>
